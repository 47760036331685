import { Modal } from '@mui/material';
import king from 'assets/image/king.svg';
import { statEvent } from 'crud';
import { useAppDispatch, useAppSelector } from 'hooks';
import React, { useEffect } from 'react';
import { setMethodPayVip, setOpenVipModal, setTopUp, setVipPump } from 'store/slices/Modal';
import { editCreateTransactions } from 'store/slices/Transactions';

import styles from './VipModal.module.scss';

const style = {
	position: 'absolute' as 'absolute',
	top: '50%',
	left: '50%',
	transform: 'translate(-50%, -50%)',
	bgcolor: '#191B1E',
	width: '90%',
	padding: 2.4,
	borderRadius: '16px',
	outline: 'none',
};

const VipModal = () => {
	const dispatch = useAppDispatch();
	const {vipPump} = useAppSelector(state => state.modal);


	return (
		<Modal
			open={vipPump}
			sx={{ backgroundColor: 'rgba(0,0,0,0.7)', overflowY: 'scroll' }}
			onClose={() => {
				dispatch(setOpenVipModal(true));
				dispatch(setVipPump(false));
			}}
		>
			<div onClick={() => {
				dispatch(setVipPump(false));
				dispatch(setOpenVipModal(true));
			}} style={{display: 'flex', alignItems: 'center', height: '100vh'}}>
				<div onClick={(e) => e.stopPropagation()} className={styles.vipContainer}>
					<img src={king} alt="" />
					<p style={{fontSize: 18, fontWeight: 600}}>You Want a VIP Access?</p>
					<p style={{fontSize: 18, fontWeight: 400, textAlign: 'center', marginTop: 25,
						marginBottom: 10,
					}}>After confirmation, You Will be Able to Purchase VIP for $70.</p>
					<p style={{fontWeight: 'bold', color: '#B4B4B4'}}>
                    1. Lifetime access to VIP pumps.<br/>
                    2. You can participate in the pump without a bot.<br/>
                    3.  Money back deposit guarantee in the event of a failed pump.<br/>
                    4. You will receive a Information for the pump 12 hours before the pump.<br/>
                    5. When you purchase VIP access, the purchase amount will be credited to your Crypto Bank balance.<br/>
                    6. VIP members do not pay any commissions.<br/>
                    7. Priority support from the main manager of the company on any question (@JohnCryptoMoon).
					</p>
					<button onClick={() => {
						dispatch(editCreateTransactions({bank: 3}));
						dispatch(statEvent('buyvip'));
						dispatch(setMethodPayVip(true));
						dispatch(setVipPump(false));
					}} className={styles.ok}>Yes, Buy ACCESS VIP CLUB for $70</button>
					<button onClick={() =>{
						dispatch(setVipPump(false));
						dispatch(statEvent('cancelvip'));
					}} className={styles.cancel}>Cancel</button>
				</div>
			</div>
		</Modal>
	);
};

export default VipModal;