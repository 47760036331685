import {
	FormControl,
	InputLabel,
	MenuItem,
	Select,
	SelectChangeEvent,
} from '@mui/material';
import { useDebounce } from '@uidotdev/usehooks';
import convert from 'assets/image/convert.svg';
import Header from 'components/Header/Header';
import { createTransaction, statEvent } from 'crud';
import { BankTypes, BankTypesReverse, CurrencyTypes, PaymentMethodTypes, TransactionTypes } from 'enum';
import { useAppDispatch, useAppSelector } from 'hooks';
import { useEffect, useState } from 'react';
import { cls } from 'utils/cls';
import { customToFixed } from 'utils/customToFixed';
import { getUserParameters } from 'utils/getUserParameters';

import styles from './ConvertCoins.module.scss';

interface ConvertCoinsProps {
	className?: string;
}

const ConvertCoins = ({ className }: ConvertCoinsProps) => {
	const [inp, setInp] = useState('');
	const debouncedSearchTerm = useDebounce(inp, 800);
	const dispatch = useAppDispatch();
	const [wallet, setWallet] = useState('1');
	const info = useAppSelector(state => state.info.info);
	const user = useAppSelector(state => state.auth.user?.user);
	const [focus, setFocus] = useState(false);

	const handleChange = (value: string) => {
		dispatch(statEvent(`convertcoins_settype_to${BankTypesReverse[value].toLocaleLowerCase()}`));
		setWallet(value);
	};

	useEffect(() => {
		if (debouncedSearchTerm) {
			dispatch(statEvent(`convertcoins_enteramount{${inp}}`));
		}
	}, [debouncedSearchTerm]);

	return (
		<div className={cls(styles.ConvertCoins, {}, [className || ''])}>
			<Header title="Convert coins" />
			<div
				style={{
					padding: '30px 14px 25px 14px',
					display: 'flex',
					flexDirection: 'column',
				}}
			>
				<div>
					<div style={{border: focus ? '1px solid rgb(76, 74, 239)' : ''}} className={styles.input}>
						<p>COINS</p>
						<input
							onKeyDown={(event) => {
								if (event.key === '-') {
									event.preventDefault();
								}
							}}
							onChange={e => setInp(e.target.value)}
							value={inp}
							min="0"
							onFocus={() => setFocus(true)} onBlur={() => setFocus(false)} type="number" />
					</div>
					<p style={{color: +inp < 25000 ||  +inp > (user?.coins || 0) ? '#D21C1C' : '#475467', fontSize: 14}}>
						{+inp < 25000 ? 'Minimum convertation from 25000 coins'
							: +inp > (user?.coins || 0) ?
								`You have ${user?.coins} coins` : 'Minimum convertation from 25000 coins'}
					</p>
				</div>

				<div className={styles.convert}>
					<img src={convert} alt="" />
					<div className={styles.dash}></div>
					<div className={styles.priceCoin}>Price 1 Coin = ${getUserParameters(info, user)?.coin_rate}</div>
				</div>

				<div className={styles.amount}>
					<p>Amount of $</p>
					<div className={styles.body_amount}>
						<FormControl>
							<Select
								labelId="demo-simple-select-label"
								id="demo-simple-select"
								value={wallet}
								// onChange={handleChange}
								sx={{color: '#D8D8D8'}}
								onOpen={() => {
									dispatch(statEvent('convertcoins_settype'));
								}}
							>
								<MenuItem onClick={() => handleChange('1')} value={BankTypes.CRYPTOBANK.toString()}>cryptobank</MenuItem>
								<MenuItem onClick={() => handleChange('2')} value={BankTypes.CRYPTOPUMP.toString()}>cryptoPUMP</MenuItem>
							</Select>
						</FormControl>
						<input disabled
							value={customToFixed((+inp * (getUserParameters(info, user)?.coin_rate as number)), 3)}
							type="number" />
					</div>
				</div>
				<button disabled={+inp < 25000 || +inp > (user?.coins || 0)} onClick={() => {
					dispatch(createTransaction({
						type: TransactionTypes['CONVERT COINS'],
						bank: +wallet,
						amount: +inp,
						currency: CurrencyTypes.OTHER,
						payment_method: PaymentMethodTypes.VISA_MASTERCARD,
					}));
					dispatch(statEvent('convertcoins_action_convert'));
				}} className={styles.btn}>
					<p>Convert and send</p>
				</button>
			</div>
		</div>
	);
};

export default ConvertCoins;
