import { Box, Checkbox, Modal, Typography } from '@mui/material';
import { useDebounce } from '@uidotdev/usehooks';
import exportIcon from 'assets/image/upload.png';
import { createTransaction, getTransactions, statEvent } from 'crud';
import { BankTypes, BankTypesReverse, CurrencyTypes, PaymentMethodTypes, TransactionTypes } from 'enum';
import { useAppDispatch, useAppSelector } from 'hooks';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { setMethodPay, setMethodPayVip, setVipPump } from 'store/slices/Modal';
import { CreateTransaction, editCreateTransactions, setSuccess } from 'store/slices/Transactions';

import styles from './Modal.module.scss';

interface OptionsData {
	value: string;
	id: number
}

interface IOptions {
	types: string
	data: OptionsData[]
}

interface ModalProps {
	className?: string;
	open: boolean;
	setOpen?: any
	title: string;
	options: IOptions;
	setNext?: any
	textCenter?: boolean;
	withdraw?: boolean;
	type: number;
}

const style = {
	position: 'absolute' as 'absolute',
	top: '50%',
	left: '50%',
	transform: 'translate(-50%, -50%)',
	bgcolor: '#191B1E',
	width: '90%',
	padding: 2.4,
	borderRadius: '16px',
	outline: 'none',
};

const ModalComponent = ({
	className,
	open,
	setOpen,
	title,
	options,
	setNext,
	textCenter,
	withdraw,
	type,
}: ModalProps) => {
	const [extra, setExtra] = useState<string>('');
	const debouncedSearchTerm = useDebounce(extra, 800);
	const dispatch = useAppDispatch();
	const createTransactions = useAppSelector(state => state.transactions.createTransaction);
	const user = useAppSelector(state => state.auth.user?.user);
	const {openVipModal} = useAppSelector(state => state.modal);
	const [active, setActive] = useState<string>(options.data[0].value);
	const navigate = useNavigate();

	useEffect(() => {
		if (type && open) {
			dispatch(editCreateTransactions({type}));
		}
	}, [type, open]);

	function getLastClipboardText() {
		dispatch(statEvent(`withdraw_${BankTypesReverse[createTransactions?.bank || ''].toLocaleLowerCase()}_buffer`));
		var text = '';
		navigator.clipboard.readText().then(clipText => {
			text = clipText;
			setExtra(text);
		}).catch(err => {
			console.error('Ошибка при чтении буфера обмена: ', err);
		});
		return text;
	}

	useEffect(() => {
		if (debouncedSearchTerm) {
			dispatch(statEvent(`withdraw_${BankTypesReverse[createTransactions?.bank || ''].toLocaleLowerCase()}_enterwallet{${extra}}`));
		}
	}, [debouncedSearchTerm]);


	return (
		<Modal
			open={open}
			sx={{ backgroundColor: 'rgba(0,0,0,0.7)' }}
			onClose={() => dispatch(setOpen(false))}
		>
			<Box sx={style}>
				<Typography sx={{ fontSize: 24, fontWeight: 600, textAlign: textCenter ? 'center' : '' }}>{title}</Typography>
				<div
					style={{
						display: 'flex',
						flexDirection: 'column',
						gap: 4,
						marginTop: 25,
					}}
				>
					{options.data.map((el, i) => (
						<div
							style={{background: (options.types === 'bank' && el.id === createTransactions?.bank) || !!(active === el.value && options.types !== 'bank')
								? 'linear-gradient(140.06deg, rgb(255, 149, 87) 7.512%,rgb(253, 208, 182) 106.995%)' : ''}}
							className={styles.el}
							onClick={() => {
								setActive(el.value);
								dispatch(editCreateTransactions({[options.types]: el.id}));
								if (((options.types === 'bank' && el.id === createTransactions?.bank) || !!(active === el.value && options.types !== 'bank'))
									&& !withdraw) {
									if (type === TransactionTypes['TOP UP']) {
										if (el.value === 'VIP PUMP|Buy a VIP membership for Top up') {
											dispatch(statEvent('topup_buyvip'));
										}
										else {
											dispatch(statEvent(`topup_${el.value.toLocaleLowerCase()}`));
										}
									}
									if (type === TransactionTypes.WITHDRAW) {
										if (el.value === 'VIP PUMP|Buy a VIP membership for Top up') {
											dispatch(statEvent('withdraw_buyvip'));
										}
										else {
											dispatch(statEvent(`withdraw_${el.value.toLocaleLowerCase()}`));
										}
									}
									if (type === TransactionTypes['BUY MINING BOT']) {
										dispatch(statEvent(`buybot_${el.value.toLocaleLowerCase()}`));
									}
									if (type === TransactionTypes['BUYVIP']) {
										dispatch(statEvent(`buyvip_${el.value.toLocaleLowerCase()}`));
									}
									dispatch(setOpen(false));
									if (active === 'VISA/MASTERCARD' && type !== 6) {
										if (type === TransactionTypes['BUY MINING BOT']) {
											dispatch(createTransaction({...createTransactions, bank: 1}));
											return;
										}
										dispatch(createTransaction({...createTransactions, payment_method: PaymentMethodTypes.VISA_MASTERCARD} as CreateTransaction));
										return;
									}
									if (active === 'VISA/MASTERCARD' && type === 6) {
										dispatch(createTransaction({...createTransactions, bank: 1}));
										return;
									}
									if (active === 'Cryptocurrency') {
										dispatch(setNext(true));
										return;
									}
									if (active === 'VIP PUMP|Buy a VIP membership for Top up') {
										if (!user?.vip) {
											navigate('/');
											dispatch(setVipPump(true));
										}
										else {
											dispatch(setNext(true));
										}
										return;
									}
									if (title === 'Select the cryptocurrency' && (type === TransactionTypes['TOP UP'] || type === TransactionTypes['BUY MINING BOT'])) {
										if (type === TransactionTypes['BUY MINING BOT']) {
											dispatch(createTransaction({...createTransactions, bank: 1, currency: el.id}));
											return;
										}
										dispatch(createTransaction({...createTransactions, currency: el.id}));
										// setNext();
										return;
									}

									if (title === 'Select the cryptocurrency' && type === 6) {
										dispatch(createTransaction({...createTransactions, currency: el.id, bank: 1}));
										// setNext();
										return;
									}
									if (active === 'VIP PUMP|Buy a VIP membership for Top up') {
										if (!user?.vip) {
											navigate('/');
											dispatch(setVipPump(true));
										}
										else {
											dispatch(setNext(true));
										}
										return;
									}

									dispatch(setNext(true));
								}
							}}
							key={i}
						>
							<p>{el.value === 'VIP PUMP|Buy a VIP membership for Top up' ?
								<>
									{ !user?.vip ? <p>{el.value.split('|')[0]} <span style={{color: '#D21C1C', fontSize: 9}}>{el.value.split('|')[1]}</span></p>:
										<span>VIP PUMP</span>
									}
								</>
								: el.value}</p>
							<Checkbox
								sx={{
									color: '#363636',
									borderRadius: 6,
								}}
								checked={(options.types === 'bank' && el.id === createTransactions?.bank) || !!(active === el.value && options.types !== 'bank')}
							/>
						</div>
					))}
					{withdraw && (
						<div style={{marginTop: 20}}>
							<p style={{color: '#B4B4B4', fontSize: 14}}>Enter your wallet address</p>

							<div className={styles.address}>
								<input
									onChange={(e) => setExtra(e.target.value)}
									value={extra}
									style={{width: '100%', backgroundColor: 'transparent',
										outline: 'none', border: 'none', color: '#B4B4B4'}} placeholder=""/>
								<img onClick={getLastClipboardText} style={{width: 15, height: 15}} src={exportIcon} alt="" />
							</div>
							<p style={{color: '#B4B4B4', fontSize: 12, marginTop: 5}}>Example: TVsaKwhXnb6hywdGahNc5u1ywNw7PpNuwB (TRC20)</p>
							<div style={{marginTop: 10}}>
								<button disabled={!extra} onClick={() => {
									dispatch(statEvent(`withdraw_${BankTypesReverse[createTransactions?.bank || ''].toLocaleLowerCase()}_withdraw`));
									dispatch(editCreateTransactions({currency: CurrencyTypes.OTHER, payment_method: PaymentMethodTypes.VISA_MASTERCARD, extra}));
									dispatch(setOpen(false));
									dispatch(setNext(true));
								}} className={styles.btn1}><p>Withdraw</p></button>
								<button onClick={() => {
									dispatch(statEvent(`withdraw_${BankTypesReverse[createTransactions?.bank || ''].toLocaleLowerCase()}_cancel`));
									dispatch(setOpen(false));
								}}
								className={styles.btn2}><p>Cancel</p></button>
							</div>
						</div>
					)}
				</div>
			</Box>
		</Modal>
	);
};

export default ModalComponent;
