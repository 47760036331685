import { Box, Modal } from '@mui/material';
import withdrawAgree from 'assets/image/withdrawAgree.svg';
import Header from 'components/Header/Header';
import ModalComponent from 'components/Modal/Modal';
import { getInfo, getMe } from 'crud';
import { BankTypes } from 'enum';
import { useAppDispatch, useAppSelector } from 'hooks';
import { useEffect, useState } from 'react';
import Sheet from 'react-modal-sheet';
import { setTopUp, setWithdraw } from 'store/slices/Modal';
import { editCreateTransactions } from 'store/slices/Transactions';
import { cls } from 'utils/cls';
import { customToFixed } from 'utils/customToFixed';
import { getTimestamp } from 'utils/getTimestamp';
import { getUserParameters } from 'utils/getUserParameters';

import Action from '../../components/Action/Action';
import styles from './CryptoVip.module.scss';

interface CryptoBankProps {
	className?: string
}

const CryptoVip = ({ className }: CryptoBankProps) => {
	const dispatch = useAppDispatch();

	const info = useAppSelector(state => state.info.info);
	const user = useAppSelector(state => state.auth.user?.user);
	const [time, setTime] = useState({
		hours: '00',
		minutes: '00',
		seconds: '00',
	});
	const userParameters = getUserParameters(info, user);
	const utcTime = new Date(info?.pump_start_dt || 0);
	const hoursUTC = utcTime.getUTCHours();
	const minutesUTC = utcTime.getUTCMinutes();

	const formattedTime = `${hoursUTC}:${minutesUTC.toString().padStart(2, '0')}`;

	useEffect(() => {
		if (info) {
			const interval = setInterval(() => {
				const endTimestamp = +getTimestamp(info?.pump_end_dt as string);
				let timer = endTimestamp - (Math.floor((new Date()).getTime() / 1000));

				if (timer === 0) {
					setTime({
						hours: '00',
						minutes: '00',
						seconds: '00',
					});
					setTimeout(() => {
						dispatch(getInfo());
						dispatch(getMe());
					}, 6000);
				}
				else if (timer >= 0) {
					const hours = Math.trunc(timer / 3600);
					timer -= hours * 3600;
					const minutes = Math.trunc(timer / 60);
					timer -= minutes * 60;
					const seconds = Math.trunc(timer);
					setTime({
						hours: hours.toString().padStart(2, '0'),
						minutes: minutes.toString().padStart(2, '0'),
						seconds: seconds.toString().padStart(2, '0'),
					});
				}
			}, 1000);

			return () => clearInterval(interval);
		}
	  }, [info]);

	function formatDateWithoutTime(dateString: string) {
		var date = new Date(dateString);
		var year = date.getFullYear();
		var month = (date.getMonth() + 1).toString().padStart(2, '0'); // Месяцы в JavaScript начинаются с 0
		var day = (date.getDate()).toString().padStart(2, '0');
		return day + '.' + month + '.' + year;
	}

	const handleChangeTopUp = () => {
		dispatch(editCreateTransactions({bank: BankTypes['BUYVIP']}));
		dispatch(setTopUp(true));
	};

	const handleChangeWithdraw = () => {
		dispatch(editCreateTransactions({bank: BankTypes['BUYVIP']}));
		dispatch(setWithdraw(true));
	};

	if (!info) return <></>;

	return (
		<div className={cls(styles.CryptoPUMP, {}, [className || ''])}>
			<Header title="VIP PUMP"/>
			<div className={styles.balance}>
				<div style={{marginBottom: 20}}>
					<p style={{color: '#363636', fontSize: 14, fontWeight: 700}}>VIP balance</p>
					<p style={{color: '#0C0C0C', fontSize: 18}}>${customToFixed((user?.vip_balance || 0), 2) || 0}</p>
				</div>
				<div>
					<p style={{color: '#363636', fontSize: 14}}>Estimated profit:</p>
					<p style={{color: '#0C0C0C', fontSize: 18}}>
						{/* ≈{customToFixed(userParameters?.cp_percent_minimum as number * 100, 1)}
					-
						{customToFixed(userParameters?.cp_percent_maximum as number * 100, 1)}% */}
						≈440-550%
					</p>
				</div>
				<div>
					<p style={{color: '#363636', fontSize: 14}}>Your estimated profit:</p>
					<p style={{color: '#0C0C0C', fontSize: 18, textAlign: 'right'}}>
						{/* eslint-disable-next-line max-len */}
						≈${customToFixed((Number(user?.vip_balance) * 4.4), 1)}-${customToFixed((Number(user?.vip_balance) * 5.5), 1)}
					</p>
				</div>
			</div>
			<Action type={'vip'} handleChangeTopUp={handleChangeTopUp} handleChangeWithdraw={handleChangeWithdraw} />

			<div className={styles.whatIs}>
				<p>What is CryptoPUMP?</p>
				<p>
					{/* eslint-disable-next-line max-len */}
					1. Lifetime access to VIP pumps.<br/>
					2. You can participate in the pump without a bot.<br/>
					3.Money back deposit guarantee in the event of a failed pump.<br/>
					4. You will receive a Information for the pump 12 hours before the pump.<br/>
					5. When you purchase VIP access, the purchase amount will be credited to your Crypto Bank balance.<br/>
					6. VIP members do not pay any commissions.<br/>
					7. Priority support from the main manager of the company on any question (@JohnCryptoMoon)
				</p>
			</div>
		</div>
	);
};

export default CryptoVip;
