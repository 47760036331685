import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';
import { IUser } from 'interface/IUser';

export interface ModalState {
	topUp: boolean;
	methodPay: boolean;
	sheetUp: boolean;
	selectCrypto: boolean;
	selectCrypto2: boolean;
	selectCrypto3: boolean;
	withdraw: boolean;
	sheetWithdraw: boolean;
	methodWithdraw: boolean;
	agreeWithdraw: boolean;
	buyMiningBot: boolean;
	methodPay2: boolean;
	methodPayVip: boolean;
	vipPump: boolean;
	selectCryptoVip: boolean;
	openVipModal: boolean
}

const initialState: ModalState = {
	topUp: false,
	methodPay: false,
	sheetUp: false,
	selectCrypto: false,
	selectCryptoVip: false,
	withdraw: false,
	sheetWithdraw: false,
	methodWithdraw: false,
	agreeWithdraw: false,
	selectCrypto2: false,
	methodPayVip: false,
	buyMiningBot: false,
	methodPay2: false,
	selectCrypto3: false,
	vipPump: false,
	openVipModal: false,
};

const modalSlice = createSlice({
	name: 'modalSlice',
	initialState,
	reducers: {
		setTopUp: (state, action: PayloadAction<boolean>) => {
			state.topUp = action.payload;
		},
		setMethodPay: (state, action: PayloadAction<boolean>) => {
			state.methodPay = action.payload;
		},
		setMethodPay2: (state, action: PayloadAction<boolean>) => {
			state.methodPay2 = action.payload;
		},
		setSheetUp: (state, action: PayloadAction<boolean>) => {
			state.sheetUp = action.payload;
		},
		setSelectCrypto: (state, action: PayloadAction<boolean>) => {
			state.selectCrypto = action.payload;
		},
		setSelectCryptoVip: (state, action: PayloadAction<boolean>) => {
			state.selectCryptoVip = action.payload;
		},
		setMethodPayVip: (state, action: PayloadAction<boolean>) => {
			state.methodPayVip = action.payload;
		},
		setSelectCrypto2: (state, action: PayloadAction<boolean>) => {
			state.selectCrypto2 = action.payload;
		},
		setSelectCrypto3: (state, action: PayloadAction<boolean>) => {
			state.selectCrypto3 = action.payload;
		},
		setOpenVipModal: (state, action: PayloadAction<boolean>) => {
			state.openVipModal = action.payload;
		},
		setWithdraw: (state, action: PayloadAction<boolean>) => {
			state.withdraw = action.payload;
		},
		setSheetWithdraw: (state, action: PayloadAction<boolean>) => {
			state.sheetWithdraw = action.payload;
		},
		setMethodWithdraw: (state, action: PayloadAction<boolean>) => {
			state.methodWithdraw = action.payload;
		},
		setAgreeWithdraw: (state, action: PayloadAction<boolean>) => {
			state.agreeWithdraw = action.payload;
		},
		setBuyMiningBot: (state, action: PayloadAction<boolean>) => {
			state.buyMiningBot = action.payload;
		},
		setVipPump: (state, action: PayloadAction<boolean>) => {
			state.vipPump = action.payload;
		},
	},
});

// Action creators are generated for each case reducer function
export const {
	setBuyMiningBot,
	setTopUp,
	setAgreeWithdraw,
	setMethodPay,
	setMethodPay2,
	setSelectCrypto3,
	setSelectCryptoVip,
	setMethodWithdraw,
	setSelectCrypto,
	setMethodPayVip,
	setSelectCrypto2,
	setSheetUp,
	setSheetWithdraw,
	setWithdraw,
	setVipPump,
	setOpenVipModal,
} = modalSlice.actions;

export default modalSlice.reducer;
