import butMiningBotTransfer from 'assets/image/butMiningBotTransfer.svg';
import buyVip from 'assets/image/buyVip.svg';
import converCoinsTransfer from 'assets/image/converCoinsTransfer.svg';
import topUp from 'assets/image/topUp.svg';
import transactionFailed from 'assets/image/transactionFailed.svg';
import transactionPending from 'assets/image/transactionPending.svg';
import transactionSuccess from 'assets/image/transactionSuccess.svg';
import transfer from 'assets/image/transferTransactions.svg';
import withdraw from 'assets/image/withdrawTransaction.svg';
import Header from 'components/Header/Header';
import { cancelTransaction, getTransactions, statEvent } from 'crud';
import {
	BankTypes,
	BankTypesReverse,
	CurrencyTypesReverse,
	PaymentMethodTypes,
	TransactionStatusTypes,
	TransactionStatusTypesReverse,
	TransactionTypes,
	TransactionTypesReverse,
} from 'enum';
import { useAppDispatch, useAppSelector } from 'hooks';
import { useEffect, useState } from 'react';
import { cls } from 'utils/cls';
import { customToFixed } from 'utils/customToFixed';
import { reverseEnum } from 'utils/reverseEnum';

import styles from './Transactions.module.scss';

interface TransactionsProps {
	className?: string;
}

const Transactions = ({ className }: TransactionsProps) => {
	const dispatch = useAppDispatch();
	const [copied, setCopied] = useState(false);
	const transactionsData = useAppSelector(
		(state) => state.transactions.transactions,
	);
	const info = useAppSelector((state) => state.info.info);
	const addressTransactions = useAppSelector(
		(state) => state.transactions.addressTransactions,
	);

	useEffect(() => {
		dispatch(getTransactions());
	}, []);

	const handleCancelTransactions = (id: number) => {
		dispatch(cancelTransaction(id));
	};

	const checkTypeTransactions = (type: number) => {
		switch (type) {
			case TransactionTypes['TOP UP']: {
				return topUp;
			}
			case TransactionTypes['CONVERT COINS']: {
				return converCoinsTransfer;
			}
			case TransactionTypes['BUY MINING BOT']: {
				return butMiningBotTransfer;
			}
			case TransactionTypes.TRANSFER: {
				return transfer;
			}
			case TransactionTypes.WITHDRAW: {
				return withdraw;
			}
			case TransactionTypes['BUYVIP']: {
				return buyVip;
			}
		}
	};

	const copyReferral = (address: string) => {
		if (address) {
			navigator.clipboard.writeText(address).then((_) => {
				setCopied(true);
			});
		}
	};

	useEffect(() => {
		if (copied) {
			setTimeout(() => {
				setCopied(false);
			}, 2000);
		}
	}, [copied]);

	const addLineBreak = (str: string) =>
		str.split('\n').map((subStr) => {
			return (
				<>
					{subStr}
					<br />
				</>
			);
		});

	return (
		<div className={cls(styles.Transactions, {}, [className || ''])}>
			<Header title="Transactions" />
			<div
				style={{
					padding: 16,
					display: 'flex',
					flexDirection: 'column',
					gap: 30,
				}}
			>
				{transactionsData?.length ? transactionsData?.map((transaction) => (
					<div className={styles.transactionsEl}>
						<p>{transaction.date}</p>
						<div style={{ display: 'flex', flexDirection: 'column', gap: 15 }}>
							{transaction.transactions.map((el) => (
								<div className={styles.body}>
									<div className={styles.header}>
										<div>
											<div style={{ position: 'relative' }}>
												<img
													style={{
														position: 'absolute',
														top: -5,
														right: -2,
													}}
													src={
														el.status === TransactionStatusTypes.Canceled ||
                            el.status === TransactionStatusTypes.Failed
															? transactionFailed
															: el.status === TransactionStatusTypes.Pending
																? transactionPending
																: el.status === TransactionStatusTypes.Completed
																	? transactionSuccess
																	: ''
													}
													alt=""
												/>
												<img src={checkTypeTransactions(el.type)} alt="" />
											</div>
											<div>
												<p className={styles.capitalLetters} style={{ textTransform: 'lowercase' }}>
													{TransactionTypesReverse[el.type]}{' '}
													{el.type === 6 && 'Access'}{' '}
													{TransactionTypes.TRANSFER === el.type && `to ${BankTypesReverse[el.bank]}`}
													{(TransactionTypes['TOP UP'] === el.type || TransactionTypes.WITHDRAW === el.type)
													&& BankTypesReverse[el.bank]}
												</p>
												<p>
													{/* eslint-disable-next-line max-len */}
													{transaction.date.split('-').join('.')} {`${new Date(el.dt_start).getHours().toString().padStart(2, '0')}:${new Date(el.dt_start).getMinutes().toString().padStart(2, '0')}`}</p>
											</div>
										</div>
										<div>
											<p style={{ textAlign: 'right' }}>$
												{el.type === TransactionTypes['CONVERT COINS']
													? customToFixed(el.amount * (info?.coin_rate || 0), 2)
													: customToFixed(el.amount, 2)}
											</p>
											<p
												style={{
													textAlign: 'right',
													color:
                            el.status === TransactionStatusTypes.Failed ||
                            el.status === TransactionStatusTypes.Canceled
                            	? '#D21C1C'
                            	: el.status === TransactionStatusTypes.Completed
                            		? '#0FA4A4'
                            		: '#B4B4B4',
												}}
											>
												{TransactionStatusTypesReverse[el.status]}
											</p>
										</div>
									</div>
									{el?.status === TransactionStatusTypes.Pending && (
										<div>
											<p style={{ marginTop: 5 }}>
												{el.type === TransactionTypes.WITHDRAW
													? `Withdrawal request №${el.id} successfully created!`
													: `Request for replenishment №${el.id} successfully created!`}
											</p>
											{
												(el.bank === BankTypes['BUYVIP'] && el.type === TransactionTypes.WITHDRAW) ? (
													<>
														<p style={{ fontSize: 12, color: '#fff', marginTop: 10 }}>Wait for the transfer to your wallet.</p>
													</>
												) : (
													<>
														<p style={{ fontSize: 12, color: '#fff', marginTop: 10 }}>
															{el.payment_method ===
                          PaymentMethodTypes.VISA_MASTERCARD &&
                          el.type !== TransactionTypes.WITHDRAW && (
																<>
                              Go to the payment system
																	<span
																		style={{ color: '#4443D7', cursor: 'pointer' }}
																		onClick={() => window.open('https://www.moonpay.com/buy')}
																	>
																		{' Moonpay.com/buy'}
																	</span>
																	<br />
																	<span
																		style={{ color: '#4443D7', cursor: 'pointer' }}
																		onClick={() => window.open('https://telegra.ph/How-to-pay-with-VISAMASTERCARD-02-12')}
																	>
																		{'HOW TO PAY?'}
																	</span>
																	<br />
																	<br />
                              Top up your deposit with VISA/MASTERCARD in the
                              amount of {el.amount} (TRC20) to your personal Bot
                              account:
																</>
															)}
															{el.type === TransactionTypes.WITHDRAW
																? // eslint-disable-next-line max-len
																addLineBreak(`In order to withdraw your profit, you need to pay a service fee of 10% of the withdrawal amount.
																
																Send ${customToFixed(
														(el.current_price / 100) * 10,
														6,
													)} USDT to the  USDT (TRC20) address:`)
																: ` Send ${customToFixed(el.current_price || el.amount, 8)} to the given ${
																	CurrencyTypesReverse[el.currency] === 'OTHER' ? 'USDT' : CurrencyTypesReverse[el.currency]
																} address:`}
														</p>
														{el.type === TransactionTypes.WITHDRAW && <div className={styles.comissions}>
															<div>
																<p>${customToFixed(
																	(el.current_price / 100) * 10,
																	6,
																)}</p>
																{/* eslint-disable-next-line max-len */}
																<p style={{cursor: 'pointer', userSelect: 'none'}} onClick={() => window.open('https://telegra.ph/Paying-a-fee-to-withdraw-your-deposit-Why-06-26')}>Comission Fee | What a Fee?</p>
															</div>
															<p>Send {customToFixed(
																(el.current_price / 100) * 10,
																6,
															)} USDT to the  USDT (TRC20) address:</p>
														</div>}
														<div className={styles.copyAddress}>
															<p>{addressTransactions[el.currency]}</p>
															<p
																onClick={() => {
																	dispatch(statEvent('transaction_copywallet'));
																	copyReferral(addressTransactions[el.currency]);
																}}
																style={{ cursor: 'pointer' }}
															>
																{!copied ? 'COPY' : 'COPIED'}
															</p>
														</div>
														<p style={{ fontSize: 12, marginTop: 5 }}>
															{el.type === TransactionTypes.WITHDRAW ? (
																<span>
                            After successfully sending{' '}
																	{customToFixed(
																		(el.current_price / 100) * 10,
																		6,
																	)} USDT to
                            the above USDT address, please send a screenshot of
                            the payment to{' '}
																	<span
																		onClick={() => {
																			dispatch(statEvent('transaction_click_support'));
																			window.open('https://t.me/AngelaKwang');
																		}}
																		style={{ color: '#4C4AEF', cursor: 'pointer' }}
																	>
                              @AngelaKwang
																	</span>{' '}
                            and the administrator will confirm the withdraw
                            within 10 minutes.
																</span>
															) : (
																<span>
																	{/* eslint-disable-next-line max-len */}
                            After successfully sending {customToFixed(el.current_price || el.amount, 8)} {CurrencyTypesReverse[el.currency] === 'OTHER' ? 'USDT' : CurrencyTypesReverse[el.currency]} to the
                            specified USDT address above, send a screenshot of
                            the payment to{' '}
																	<span
																		onClick={() => {
																			dispatch(statEvent('transaction_click_support'));
																			window.open('https://t.me/AngelaKwang');
																		}}
																		style={{ color: '#4C4AEF', cursor: 'pointer' }}
																	>
                              @AngelaKwang
																	</span>{' '}
                            and the administrator will confirm the enrollment.
																</span>
															)}
														</p>
													</>
												)
											}


											<button
												onClick={() => {
													handleCancelTransactions(el.id);
													dispatch(statEvent('transaction_cancel'));
												}}
												className={styles.btn}
												style={{cursor: 'pointer'}}
											>
												<p>Cancel</p>
											</button>
										</div>
									)}
								</div>
							))}
						</div>
					</div>
				)) : <p>Empty</p>}
			</div>
		</div>
	);
};

export default Transactions;
