import { Box, Modal } from '@mui/material';
import { useDebounce } from '@uidotdev/usehooks';
import withdrawAgree from 'assets/image/withdrawAgree.svg';
import Footer from 'components/Footer/Footer';
import LoadingModal from 'components/LoadingModal/LoadingModal';
import ModalComponent from 'components/Modal/Modal';
import { createTransaction, getTransactions, statEvent } from 'crud';
import { BankTypesReverse, CurrencyTypes, CurrencyTypesReverse, PaymentMethodTypes, TransactionTypes } from 'enum';
import { useAppDispatch, useAppSelector } from 'hooks';
import Admin from 'pages/Admin/Admin';
import Calculator from 'pages/Calculator/Calculator';
import ConvertCoins from 'pages/ConvertCoins/ConvertCoins';
import CryptoBank from 'pages/CryptoBank/CryptoBank';
import CryptoGame from 'pages/CryptoGame/CryptoGame';
import CryptoGameGuide from 'pages/CryptoGameGuide/CryptoGameGuide';
import CryptoPUMP from 'pages/CryptoPUMP/CryptoPUMP';
import CryptoVip from 'pages/CryptoVip/CryptoVip';
import Friends from 'pages/Friends/Friends';
import Home from 'pages/Home/Home';
import MiningBot from 'pages/MiningBot/MiningBot';
import Notification from 'pages/Notification/Notification';
import Payouts from 'pages/Payouts/Payouts';
import Setting from 'pages/Setting/Setting';
import Start from 'pages/Start/Start';
import Transactions from 'pages/Transactions/Transactions';
import Transfer from 'pages/Transfer/Transfer';
import { useEffect, useLayoutEffect, useMemo, useState } from 'react';
import Sheet from 'react-modal-sheet';
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import { setAgreeWithdraw, setBuyMiningBot, setMethodPay, setMethodPay2, setMethodPayVip, setMethodWithdraw, setSelectCrypto,
	setSelectCrypto2, setSelectCrypto3, setSelectCryptoVip, setSheetUp, setSheetWithdraw, setTopUp, setWithdraw } from 'store/slices/Modal';
import { CreateTransaction, editCreateTransactions, setSuccess } from 'store/slices/Transactions';
import { getBalance } from 'utils/getBalance';
import { getItem } from 'utils/localstorage';

import styles from './Navigation.module.scss';

const style = {
	position: 'absolute' as 'absolute',
	top: '50%',
	left: '50%',
	transform: 'translate(-50%, -50%)',
	outline: 'none',
	width: '90%',
	bgcolor: '#191B1E',
	borderRadius: '16px',
	display: 'flex',
	flexDirection: 'column',
	alignItems: 'center',
};

const options = {
	types: 'bank',
	data: [
		{id: 1,value: 'Cryptobank'},
		{id: 2, value: 'CryptoPUMP'},
		{id: 3, value: 'VIP PUMP|Buy a VIP membership for Top up'},
	],
};

const options2 = {
	types: 'payment_method',
	data: [
		{id: 1, value: 'Cryptocurrency'},
		{id: 2, value: 'VISA/MASTERCARD'},
	],
};

const options3 = {
	types: 'currency',
	data: [
		{id: 1, value: 'USDT(TRC20)'},
		{id: 2, value: 'BTC(BTC)'},
		{id: 3, value: 'BNB(BEP20)'},
		{id: 4, value: 'DOGE(DOGE)'},
		{id: 5, value: 'TRX(TRON TRC20)'},
	]};

const options4 = {
	types: 'currency',
	data: [
		{id: 1, value: 'Cryptocurrency'},
	]};

const Navigation = () => {
	const navigate = useNavigate();
	const [valueInp, setValueInp] = useState<number | null>(null);
	const debouncedSearchTerm = useDebounce(valueInp, 800);
	const dispatch = useAppDispatch();
	const location = useLocation();
	const createTransactions = useAppSelector(state => state.transactions.createTransaction);
	const {buyMiningBot ,selectCrypto, selectCrypto3, selectCryptoVip, methodPayVip,
		withdraw, sheetUp, sheetWithdraw, agreeWithdraw, methodWithdraw, topUp, methodPay, methodPay2}
	= useAppSelector(state => state.modal);
	const transactionsSuccess = useAppSelector(state => state.transactions.success);
	const transactionsLoading = useAppSelector(state => state.transactions.isLoading);
	const transactions = useAppSelector(state => state.transactions.createTransaction);
	const user = useAppSelector(state => state.auth.user);
	const valueInp2 = useMemo(() => getBalance(createTransactions, user?.user), [user, createTransactions]);

	useEffect(() => {
		if (transactionsSuccess && !transactionsLoading) {
			location.pathname !== '/transactions' ? navigate('/transactions') : dispatch(getTransactions());
			dispatch(setSuccess(false));
		}
	}, [transactionsSuccess, transactionsLoading]);

	useEffect(() => {
		if (sheetUp || sheetWithdraw) {
			window?.scrollTo({
				top: window.pageYOffset + 1,
				behavior: 'auto',
		  });
		}
	}, [sheetUp, sheetWithdraw]);

	useEffect(() => {
		if (debouncedSearchTerm) {
			dispatch(statEvent(`topup_customamount{${valueInp}}`));
		}
	}, [debouncedSearchTerm]);

	return (
		<div>
			<Routes>
				{user ? !user?.user?.checkbox ?
					<>
						<Route path="/" element={<Start />} />
						<Route path="*" element={<Start />} />
					</>
					:
					<>
						<Route path="/" element={<Home />} />
						<Route path="*" element={<Home />} />
						<Route path="/cryptobank" element={<CryptoBank />} />
						<Route path="/cryptoPUMP" element={<CryptoPUMP />} />
						<Route path="/cryptoGame" element={<CryptoGame />} />
						<Route path="/cryptoGameGuide" element={<CryptoGameGuide />} />
						<Route path="/friends" element={<Friends />} />
						<Route path="/miningBot" element={<MiningBot />} />
						<Route path="/calculator" element={<Calculator />} />
						<Route path="/convertCoins" element={<ConvertCoins />} />
						<Route path="/transactions" element={<Transactions />} />
						<Route path="/transfer" element={<Transfer />} />
						<Route path="/notification" element={<Notification />} />
						<Route path="/setting" element={<Setting />} />
						<Route path="/payouts" element={<Payouts />} />

						{user?.user?.groups?.includes('admins') && <Route path="/admin" element={<Admin />} />}
						{user?.user?.vip && <Route path="/cryptoVip" element={<CryptoVip />} />}
					</> : null}
			</Routes>
			{!user && <LoadingModal /> }
			{user?.user.checkbox && <Footer />}


			<ModalComponent open={topUp} setOpen={setTopUp} setNext={setSheetUp} type={1} title={'Top up'} options={options}/>
			<ModalComponent textCenter={true}
				type={1} open={methodPay} setOpen={setMethodPay} setNext={setSelectCrypto} title={'Select the payment method'} options={options2}/>

			<ModalComponent textCenter={true} type={1} open={selectCrypto}
				setOpen={setSelectCrypto} setNext={() => navigate('transactions')} title={'Select the cryptocurrency'} options={options3}/>

			<ModalComponent textCenter={true} type={6} open={selectCryptoVip}
				setOpen={setSelectCryptoVip} setNext={() => navigate('transactions')} title={'Select the cryptocurrency'} options={options3}/>

			<ModalComponent textCenter={true}
				type={6} open={methodPayVip} setOpen={setMethodPayVip} setNext={setSelectCryptoVip} title={'Select the payment method'} options={options2}/>

			{/* <ModalComponent textCenter={true} type={2} open={selectCrypto2}
				setOpen={setSelectCrypto2} setNext={setAgreeWithdraw} title={'Select the cryptocurrency'} options={options3}/> */}

			<ModalComponent type={2} open={withdraw} setOpen={setWithdraw} title={'Withdraw'} setNext={setSheetWithdraw} options={options}/>

			<ModalComponent type={2} textCenter={true} open={methodWithdraw}
				setOpen={setMethodWithdraw} title={'Select the withdraw method'} options={options4}
				withdraw={true} setNext={setAgreeWithdraw}
			/>


			{/* <ModalComponent open={buyMiningBot} type={TransactionTypes['BUY MINING BOT']} title={'Buy mining bot'}
				options={options}
				setOpen={setBuyMiningBot}
				setNext={setMethodPay2}
			/> */}

			<ModalComponent textCenter={true}
				type={TransactionTypes['BUY MINING BOT']}
				open={methodPay2} setOpen={setMethodPay2} setNext={setBuyMiningBot} title={'Select the payment method'} options={options2}/>

			<ModalComponent textCenter={true} type={TransactionTypes['BUY MINING BOT']} open={buyMiningBot}
				setOpen={setBuyMiningBot} setNext={() => navigate('transactions')} title={'Select the cryptocurrency'} options={options3}/>

			<Modal open={agreeWithdraw}>
				<Box sx={{...style,
					padding: '24px 16px',
				}}>
					<img src={withdrawAgree} alt="" />
					<p style={{fontWeight: 600, marginTop: 15}}>Are you sure?</p>
					<p style={{fontSize: 13, textAlign: 'center', color: '#B4B4B4', marginTop: 10}}>
						You want to withdraw ${createTransactions?.amount} USD to {createTransactions?.extra}
						<br /> Is everything right?</p>
					<button onClick={() => {
						dispatch(statEvent(`withdraw_${BankTypesReverse[createTransactions?.bank || ''].toLocaleLowerCase()}_confirm_yeswithdraw`));
						dispatch(createTransaction({...createTransactions as CreateTransaction, payment_method: PaymentMethodTypes.CRYPTOCURRENCY,
							currency: CurrencyTypes.USDT,
						}));
						dispatch(setAgreeWithdraw(false));
						navigate('/transactions');
					}} className={styles.btn1}><p>Yes, Withdraw</p></button>
					<button onClick={() => {
						dispatch(statEvent(`withdraw_${BankTypesReverse[createTransactions?.bank || ''].toLocaleLowerCase()}_confirm_cancel`));
						dispatch(setAgreeWithdraw(false));
					}} className={styles.btn2}><p>Cancel</p></button>
				</Box>
			</Modal>

			<Modal open={sheetUp}>
				<Box sx={style}>
					<Sheet detent="content-height" isOpen={sheetUp} onClose={() => {
						dispatch(statEvent(`topup_${BankTypesReverse[transactions?.bank || ''].toLocaleLowerCase()}_swipe`));
						dispatch(setSheetUp(false));
					}}>
						<Sheet.Container style={{backgroundColor: '#0C0C0C'}}>
							<Sheet.Header />
							<Sheet.Content>
								<div className={styles.sheet}>
									<p>Top up</p>
									<p>How much do you want to top up?</p>
									<div className={styles.amount}>
										<p>Amount</p>
										<div className={styles.input}>
											<p>$</p>
											<input onKeyDown={(event) => {
												if (event.key === '-') {
													event.preventDefault();
												}
											}}
											onChange={(e) => {
												setValueInp(+e?.target?.value);
											}} value={valueInp || ''} placeholder="1,000.00" type="number" />
											<p>USD</p>
										</div>
									</div>
									{Number(valueInp) < 50 && <p style={{color: '#9E1515', marginTop: 10, fontSize: 18}}>Minimum deposit from 50 USD</p>}
									<div className={styles.sum}>
										<button onClick={() => {
											dispatch(statEvent('topup_50'));
											setValueInp(50);
										}}><p>$50.00</p></button>
										<button onClick={() => {
											dispatch(statEvent('topup_100'));
											setValueInp(100);
										}}><p>$100.00</p></button>
										<button onClick={() => {
											dispatch(statEvent('topup_500'));
											setValueInp(500);
										}}><p>$500.00</p></button>
										<button onClick={() => {
											dispatch(statEvent('topup_1000'));
											setValueInp(1000);
										}}><p>$1000.00</p></button>
									</div>
									<button disabled={!(Number(valueInp) >= 50)} onClick={() => {
										if ((valueInp || 0) >= 50) {
											dispatch(statEvent('topup_continue'));
											dispatch(editCreateTransactions({amount: Number(valueInp)}));
											dispatch(setMethodPay(true));
											dispatch(setSheetUp(false));
										}
									}} className={styles.btn}><p>Continue</p></button>
								</div>
							</Sheet.Content>
						</Sheet.Container>
						<Sheet.Backdrop />
					</Sheet>
				</Box>
			</Modal>

			<Modal open={sheetWithdraw}>
				<Box sx={style}>
					<Sheet detent="content-height" isOpen={sheetWithdraw} onClose={() => {
						dispatch(statEvent(`withdraw_${BankTypesReverse[transactions?.bank || ''].toLocaleLowerCase()}_swipe`));
						dispatch(setSheetWithdraw(false));
					}}>
						<Sheet.Container style={{backgroundColor: '#0C0C0C'}}>
							<Sheet.Header />
							<Sheet.Content>
								<div className={styles.sheet}>
									<p>Withdraw</p>
									<p>Amount available for withdrawal:</p>
									<div className={styles.amount}>
										<p>Amount</p>
										<div className={styles.input}>
											<p>$</p>
											<input disabled
												onKeyDown={(event) => {
													if (event.key === '-') {
														event.preventDefault();
													}
												}}
												min="0"
												// onChange={(e) => setValueInp2(e?.target?.value)}
												value={valueInp2} placeholder="1,000.00" type="number" />
											<p>USD</p>
										</div>
										{Number(valueInp2) < 100 && <p style={{color: '#9E1515', marginTop: 10, fontSize: 18}}>Minimum withdrawal 100 USDT</p>}
									</div>
									<button disabled={!(valueInp2 > 50)} onClick={() => {
										dispatch(statEvent(`withdraw_${BankTypesReverse[transactions?.bank || ''].toLocaleLowerCase()}_continue`));
										dispatch(editCreateTransactions({amount: +valueInp2}));
										dispatch(setSheetWithdraw(false));
										dispatch(setMethodWithdraw(true));
									}}
									style={{marginTop: Number(valueInp2) > 100 ? 20 : 0 }}
									className={styles.btn}><p>Continue</p></button>
								</div>
							</Sheet.Content>
						</Sheet.Container>
						<Sheet.Backdrop />
					</Sheet>
				</Box>
			</Modal>
		</div>
	);
};

export default Navigation;
