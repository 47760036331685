import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import Plus from 'assets/image/Plus.svg';
import Transfer from 'assets/image/transfer.svg';
import WithdrawWhite from 'assets/image/withdrawWhite.svg';
import { statEvent } from 'crud';
import { useAppDispatch } from 'hooks';
import { useNavigate } from 'react-router-dom';
import { cls } from 'utils/cls';

import styles from './Action.module.scss';

interface ActionProps {
	className?: string;
	handleChangeTopUp?: () => void;
	handleChangeWithdraw?: () => void;
	type: string
}

const Action = ({ className, handleChangeTopUp, handleChangeWithdraw, type }: ActionProps) => {
	const navigate = useNavigate();
	const dispatch = useAppDispatch();
	return (
		<div className={cls(styles.Action, {}, [className || ''])}>
			<div
				style={{gap: type === 'vip' ? 5 : ''}}
			>
				<button onClick={() => {
					dispatch(statEvent(`${type}_topup`));
					handleChangeTopUp &&handleChangeTopUp();
				}}
				style={{width: type === 'vip' ? '100%' : ''}}
				>
					<img src={Plus} alt="" />
					<p>Top Up</p>
				</button>
				{ type !== 'vip' && <button onClick={() => {
					dispatch(statEvent(`${type}_transfer`));
					navigate('/transfer');}
				}>
					<img src={Transfer} alt="" />
					<p>Transfer</p>
				</button>}
				<button onClick={() => {
					dispatch(statEvent(`${type}_withdraw`));
					handleChangeWithdraw && handleChangeWithdraw();
				}}
				style={{width: type === 'vip' ? '100%' : ''}}
				>
					<img src={WithdrawWhite} alt="" />
					<p>Withdraw</p>
				</button>
			</div>
			{<div>
				<button onClick={() => {
					dispatch(statEvent(`${type}_calculateprofit`));
					navigate('/calculator');
				}} className={styles.calculate}>
					<p>Calculate profit</p>
					<ArrowForwardIosIcon />
				</button>
			</div>}

		</div>
	);
};

export default Action;
