import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';
import { createOrGetUser } from 'crud';
import { IClicksData } from 'interface/IClicksData';
import { IFriends } from 'interface/IFriends';
import { IAllUsers, IUser, User } from 'interface/IUser';

export interface AuthState {
	user: IUser | null;
	friends: IFriends | null;
	isLoading: boolean;
	error: string;
	reject: boolean
}

const initialState: AuthState = {
	user: null,
	friends: null,
	isLoading: false,
	error: '',
	reject: false,
};

const authSlice = createSlice({
	name: 'authSlice',
	initialState,
	reducers: {
		addUser: (state, action: PayloadAction<IUser>) => {
			state.user = action.payload;
		},
		addFriends: (state, action: PayloadAction<IFriends>) => {
			state.friends = action.payload;
		},
		addMe: (state, action: PayloadAction<User>) => {
			if (state.user) {
				state.user.user = action.payload;
			}
		},
		editClickData: (state, action: PayloadAction<IClicksData>) => {
			if (state.user) {
				state.user = {
					...state.user,
					user: {
						...state.user.user,
						coins: action.payload.coins,
						energy: action.payload.energy,
					},
				};
			}
		},
		editClicks: (state, action: PayloadAction<IClicksData>) => {
			if (state.user) {
				const { user } = state.user;
				state.user = {
					...state.user,
					user: {
						...user,
						coins: user.coins + action.payload.coins,
						energy: user.energy - action.payload.energy,
					},
				};
			}
		},
		setBotWorking: (state, action: PayloadAction<boolean>) => {
			if (state.user && state.user.user && state.user.user.mining_bot) {
				state.user.user.mining_bot.is_working = action.payload;
			}
		},
		setReject: (state, action: PayloadAction<boolean>) => {
			state.reject = action.payload;
		},
		addEnergy: (state, action: PayloadAction<number>) => {
			if (state.user) {
				state.user = {
					...state.user,
					user: {
						...state.user.user,
						energy: state.user.user.energy + action.payload,
					},
				};
			}
		},
		setLoadingAuth: (state, action: PayloadAction<boolean>) => {
			state.isLoading = action.payload;
		},
	},
	extraReducers: (builder) => {
		builder.addCase(createOrGetUser.rejected, (state, action: any) => {
			state.error = action.payload.error as string; // Устанавливаем ошибку
		});
	},
});

// Action creators are generated for each case reducer function
export const {
	addUser,
	editClicks,
	editClickData,
	addEnergy,
	setBotWorking,
	addMe,
	setLoadingAuth,
	addFriends,
	setReject,
} = authSlice.actions;

export default authSlice.reducer;
