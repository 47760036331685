import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { REACT_APP_API_DOMAIN } from 'config/api';
import { GameDirection } from 'enum';
import { ITransactions } from 'interface/ITransactions';
import { useNavigate } from 'react-router-dom';
import { RootState } from 'store';
import {
	setAllUsers,
	setError,
	setError2,
	setLoadingAdmin,
	setSuccessAdmin,
} from 'store/slices/Admin';
import {
	addFriends,
	addMe,
	addUser,
	editClickData,
	editClicks,
	setLoadingAuth,
} from 'store/slices/Auth';
import { addClicks, setSuccessClick } from 'store/slices/ClicksMine';
import {
	setLastGame,
	setLoadingGame,
	setPriceBNB,
} from 'store/slices/CryptoGame';
import { setInfo, setLoadingInfo } from 'store/slices/Info';
import {
	clearIsViewed,
	clearNotifications,
	setNewNotifications,
	setNotifications,
} from 'store/slices/Notifications';
import { setLoadingPayouts, setPayouts } from 'store/slices/Payouts';
import {
	setLoadingStatistics,
	setStatisticsAdmin,
	setStatisticsPublic,
	setTopInvestors,
} from 'store/slices/Statistics';
import {
	addTransactions,
	clearCreateTransactions,
	CreateTransaction,
	editCreateTransactions,
	setLoadingTransactions,
	setSuccess,
} from 'store/slices/Transactions';
import { getItem, getItemSession, setItem } from 'utils/localstorage';

interface IDataClicks {
	clicks: number;
	energy: number;
}

export const api = axios.create({
	baseURL: REACT_APP_API_DOMAIN,
	headers: getItem('token')
		? {
			Authorization: `Token ${getItem('token')}`,
			'ngrok-skip-browser-warning': 6024,
		}
		: {},
});

export const createOrGetUser = createAsyncThunk(
	'users',
	async (data: { initData: string; referral: any | null, session_id: string }, thunkAPI) => {
		try {
			let get_country = true;
			thunkAPI.dispatch(setLoadingAuth(true));
			if (getItem('lastDateAuth')) {
				const givenDate = new Date(getItem('lastDateAuth') || '');

				// Текущая дата
				const currentDate: Date = new Date();

				// Разница во времени в миллисекундах
				const timeDifference: number =
          currentDate.getTime() - givenDate.getTime();

				// Количество миллисекунд в одной неделе
				const oneWeekInMilliseconds: number = 7 * 24 * 60 * 60 * 1000;

				// Проверяем, прошло ли больше недели
				if (timeDifference > oneWeekInMilliseconds) {
					get_country = true;
					setItem('lastDateAuth', new Date().toString());
				} else {
					get_country = false;
				}
			}
			const requestData: any = {
				webData: data.initData,
				referral: data.referral,
				get_country,
				session_id: data.session_id,
			};

			const res = await api.post('users/', requestData, {
				headers: {
					Authorization: '',
				},
			});

			if (res.data?.token) {
				if (!getItem('lastDateAuth')) {
					setItem('lastDateAuth', new Date().toString());
					setItem('country', res.data.country);
				}
				api.interceptors.request.use((config) => {
					const token = getItem('token');
					if (token) {
						config.headers.Authorization = `Token ${token}`;
					}
					return config;
				});
				thunkAPI.dispatch(addUser(res.data));
				setItem('token', res.data?.token);
				thunkAPI.dispatch(setLoadingAuth(false));
			}
		} catch (err: any) {
			thunkAPI.dispatch(setLoadingAuth(false));
			thunkAPI.dispatch(setError(true));
			return thunkAPI.rejectWithValue({ error: err?.message });
		}
	},
);

export const getMe = createAsyncThunk('users/me', async (_, thunkAPI: any) => {
	try {
		thunkAPI.dispatch(setLoadingAuth(true));
		const res = await api.get('users/me/');
		if (res.data) {
			thunkAPI.dispatch(addMe(res?.data));
			thunkAPI.dispatch(setLoadingAuth(false));
		}
	} catch (err) {
		// return thunkAPI.rejectWithValue({ error: err.message });
		thunkAPI.dispatch(setLoadingAuth(false));
		thunkAPI.dispatch(setError(true));
	}
});

export const addClicksMine = createAsyncThunk(
	'users/add_clicks',
	async (data: IDataClicks, thunkAPI) => {
		try {
			const res = await api.post('users/add_clicks/', { clicks: data.clicks });
			if (res.data) {
				thunkAPI.dispatch(addClicks(res.data));
				// @ts-ignore
				!thunkAPI.getState()?.clickMine?.success &&
          thunkAPI.dispatch(setSuccessClick(true));
			}
		} catch (err) {
			// return thunkAPI.rejectWithValue({ error: err.message });
			thunkAPI.dispatch(setError(true));
		}
	},
);

export const addClicksMineExit = createAsyncThunk<
void,
void,
{ state: RootState }
>('users/add_clicks', async (_, thunkAPI) => {
	try {
		const res = await api.post('users/add_clicks/', {
			clicks: thunkAPI.getState().clickMine.clickSum,
		});
		if (res.data) {
			thunkAPI.dispatch(addClicks(res.data));
			// @ts-ignore
			!thunkAPI.getState()?.clickMine?.success &&
        thunkAPI.dispatch(setSuccessClick(true));
		}
	} catch (err) {
		// return thunkAPI.rejectWithValue({ error: err.message });
	}
});

export const getTransactions = createAsyncThunk(
	'/transactions/',
	async (_, thunkAPI) => {
		try {
			thunkAPI.dispatch(setLoadingTransactions(true));
			const res = await api.get('transactions/');
			thunkAPI.dispatch(addTransactions(res.data));
			thunkAPI.dispatch(setLoadingTransactions(false));
		} catch (err) {
			// return thunkAPI.rejectWithValue({ error: err.message });
			thunkAPI.dispatch(setLoadingTransactions(false));
			thunkAPI.dispatch(setError(true));
		}
	},
);

export const createTransaction = createAsyncThunk(
	'/transactions',
	async (data: CreateTransaction, thunkAPI) => {
		try {
			thunkAPI.dispatch(setLoadingTransactions(true));
			const res = await api.post('transactions/', {
				...data,
				currency: !data.currency ? 1000 : data.currency,
			});
			thunkAPI.dispatch(clearCreateTransactions());
			thunkAPI.dispatch(getMe());
			thunkAPI.dispatch(setLoadingTransactions(false));
			if (res.status === 200) {
				thunkAPI.dispatch(setSuccess(true));
			}
		} catch (err) {
			// return thunkAPI.rejectWithValue({ error: err.message });
			thunkAPI.dispatch(setLoadingTransactions(false));
			thunkAPI.dispatch(setError(true));
		}
	},
);

export const cancelTransaction = createAsyncThunk(
	'/transactions/cancel',
	async (id: number, thunkAPI) => {
		try {
			thunkAPI.dispatch(setLoadingTransactions(true));
			const res = await api.post(`transactions/${id}/cancel/`);
			thunkAPI.dispatch(getMe());
			thunkAPI.dispatch(getTransactions());
		} catch (err) {
			// return thunkAPI.rejectWithValue({ error: err.message });
			thunkAPI.dispatch(getTransactions());
			thunkAPI.dispatch(setLoadingTransactions(false));
		}
	},
);

export const getInfo = createAsyncThunk<void, void, { state: RootState }>(
	'/info',
	async (_, thunkAPI) => {
		try {
			thunkAPI.dispatch(setLoadingInfo(true));
			const res = await api.get('info/', {
				headers: {
					Authorization: `Token ${thunkAPI?.getState().auth.user?.token}`,
				},
			});
			thunkAPI.dispatch(setLoadingInfo(false));
			thunkAPI.dispatch(setInfo(res.data));
		} catch (err) {
			// return thunkAPI.rejectWithValue({ error: err.message });
			thunkAPI.dispatch(setLoadingInfo(false));
			thunkAPI.dispatch(setError(true));
		}
	},
);

export const getFriends = createAsyncThunk('/friend', async (_, thunkAPI) => {
	try {
		const res = await api.get('transactions/friends/');
		thunkAPI.dispatch(addFriends(res.data));
	} catch (err) {
		// return thunkAPI.rejectWithValue({ error: err.message });
	}
});

export const getNotifications = createAsyncThunk(
	'/notifications',
	async (_, thunkAPI) => {
		try {
			const res = await api.get('notifications/');
			thunkAPI.dispatch(setNotifications(res.data));
		} catch (err) {
			// return thunkAPI.rejectWithValue({ error: err.message });
		}
	},
);

export const readNotifications = createAsyncThunk<
void,
void,
{ state: RootState }
>('/notifications', async (_, thunkAPI) => {
	try {
		const res = await api.post('notifications/mark_as_read/', {
			ids: thunkAPI.getState().notification.isViewed,
		});
		thunkAPI.dispatch(clearIsViewed());
		thunkAPI.dispatch(getNotIsViewed());
	} catch (err) {
		// return thunkAPI.rejectWithValue({ error: err.message });
	}
});

export const getNotIsViewed = createAsyncThunk<
void,
void,
{ state: RootState }
>('/notifications', async (_, thunkAPI) => {
	try {
		const res = await api.get('notifications/has_new/', {
			headers: {
				Authorization: `Token ${thunkAPI?.getState().auth.user?.token}`,
			},
		});
		thunkAPI.dispatch(setNewNotifications(res.data?.has_new));
	} catch (err) {
		// return thunkAPI.rejectWithValue({ error: err.message });
	}
});

export const deleteNotification = createAsyncThunk<
void,
void,
{ state: RootState }
>('/notifications', async (_, thunkAPI) => {
	try {
		const res = await api.post('notifications/delete_all/');
		thunkAPI.dispatch(clearNotifications());
		// thunkAPI.dispatch(setNewNotifications(res.data?.has_new));
	} catch (err) {
		// return thunkAPI.rejectWithValue({ error: err.message });
	}
});

export const getPriceBNB = createAsyncThunk('/binance', async (_, thunkAPI) => {
	try {
		const res = await api.get(
			'https://api.binance.com/api/v1/ticker/price?symbol=BNBUSDT',
		);
		thunkAPI.dispatch(setPriceBNB(res.data.price));
	} catch (err) {
		// return thunkAPI.rejectWithValue({ error: err.message });
	}
});

export const getLastGame = createAsyncThunk(
	'/crypto_game',
	async (_, thunkAPI) => {
		try {
			thunkAPI.dispatch(setLoadingGame(true));
			const res = await api.get('crypto_game/');
			thunkAPI.dispatch(setLoadingGame(false));
			thunkAPI.dispatch(setLastGame(res.data));
			thunkAPI.dispatch(getMe());
		} catch (err) {
			// return thunkAPI.rejectWithValue({ error: err.message });
			thunkAPI.dispatch(setLoadingGame(false));
			thunkAPI.dispatch(setError(true));
		}
	},
);

export const createGame = createAsyncThunk<
void,
{ percent: number; direction: GameDirection }
>('/crypto_game', async (data, thunkAPI) => {
	try {
		thunkAPI.dispatch(setLoadingGame(true));
		const res = await api.post('crypto_game/', data);
		setItem('gameViewed', '');
		thunkAPI.dispatch(setLoadingGame(false));
		thunkAPI.dispatch(getMe());
		thunkAPI.dispatch(setLastGame(res.data));
	} catch (err) {
		// return thunkAPI.rejectWithValue({ error: err.message });
		thunkAPI.dispatch(setLoadingGame(false));
		thunkAPI.dispatch(setError(true));
	}
});

export const getPayouts = createAsyncThunk<void, void>(
	'/payouts',
	async (_, thunkAPI) => {
		try {
			thunkAPI.dispatch(setLoadingPayouts(true));
			const res = await api.get('payouts/');
			thunkAPI.dispatch(setLoadingPayouts(false));
			thunkAPI.dispatch(setPayouts(res.data));
		} catch (err) {
			// return thunkAPI.rejectWithValue({ error: err.message });
			thunkAPI.dispatch(setLoadingPayouts(false));
			thunkAPI.dispatch(setError(true));
		}
	},
);

export const changeInfo = createAsyncThunk<void, any>(
	'/info',
	async (data, thunkAPI) => {
		try {
			thunkAPI.dispatch(setLoadingInfo(true));
			const res = await api.patch('info/', data);
			thunkAPI.dispatch(setSuccessAdmin(true));
			thunkAPI.dispatch(setLoadingInfo(false));
		} catch (err) {
			// return thunkAPI.rejectWithValue({ error: err.message });
			thunkAPI.dispatch(setLoadingInfo(false));
			thunkAPI.dispatch(setError(true));
		}
	},
);

export const changeUser = createAsyncThunk<void, any>(
	'/users',
	async (data, thunkAPI) => {
		const { userId, ...dataNext } = data;
		try {
			thunkAPI.dispatch(setLoadingAuth(true));
			const res = await api.patch(`users/${userId}/`, dataNext);
			thunkAPI.dispatch(setSuccessAdmin(true));
			thunkAPI.dispatch(setLoadingAuth(false));
		} catch (err) {
			// return thunkAPI.rejectWithValue({ error: err.message });
			thunkAPI.dispatch(setLoadingAuth(false));
			thunkAPI.dispatch(setError(true));
		}
	},
);

export const giveBonus = createAsyncThunk<
void,
{
	userId: string;
	value: number;
	bank: number;
}
>('/users', async (data, thunkAPI) => {
	const { userId, value, bank } = data;
	try {
		thunkAPI.dispatch(setLoadingInfo(true));
		const res = await api.post(`users/${userId}/give_money/`, {
			bank,
			amount: +value,
		});
		thunkAPI.dispatch(setSuccessAdmin(true));

		thunkAPI.dispatch(setLoadingInfo(false));
	} catch (err) {
		// return thunkAPI.rejectWithValue({ error: err.message });
		thunkAPI.dispatch(setLoadingInfo(false));
		thunkAPI.dispatch(setError(true));
	}
});

export const sendNotification = createAsyncThunk<void, any>(
	'/notifications',
	async (data, thunkAPI) => {
		try {
			thunkAPI.dispatch(setLoadingInfo(true));
			const res = await api.post('notifications/send/', data);
			thunkAPI.dispatch(setSuccessAdmin(true));

			thunkAPI.dispatch(setLoadingInfo(false));
		} catch (err) {
			// return thunkAPI.rejectWithValue({ error: err.message });
			thunkAPI.dispatch(setLoadingInfo(false));
			thunkAPI.dispatch(setError(true));
		}
	},
);

export const getStatisticsPublic = createAsyncThunk<void, void>(
	'/statistics',
	async (_, thunkAPI) => {
		try {
			thunkAPI.dispatch(setLoadingStatistics(true));
			const res = await api.get('info/public_stats/');
			thunkAPI.dispatch(setStatisticsPublic(res.data));
			thunkAPI.dispatch(setLoadingStatistics(false));
		} catch (err) {
			// return thunkAPI.rejectWithValue({ error: err.message });
			thunkAPI.dispatch(setLoadingStatistics(false));
			thunkAPI.dispatch(setError(true));
		}
	},
);

export const getStatisticsAdmin = createAsyncThunk<void, void>(
	'/statistics',
	async (_, thunkAPI) => {
		try {
			thunkAPI.dispatch(setLoadingStatistics(true));
			const res = await api.get('info/admin_stats/');
			thunkAPI.dispatch(setStatisticsAdmin(res.data));
			thunkAPI.dispatch(setLoadingStatistics(false));
		} catch (err) {
			// return thunkAPI.rejectWithValue({ error: err.message });
			thunkAPI.dispatch(setLoadingStatistics(false));
			thunkAPI.dispatch(setError(true));
		}
	},
);

export const sendTelegramNotification = createAsyncThunk<void, any>(
	'/notifications',
	async (data, thunkAPI) => {
		try {
			thunkAPI.dispatch(setLoadingInfo(true));
			const res = await api.post('notifications/telegram/', data);
			thunkAPI.dispatch(setSuccessAdmin(true));

			thunkAPI.dispatch(setLoadingInfo(false));
		} catch (err) {
			// return thunkAPI.rejectWithValue({ error: err.message });
			thunkAPI.dispatch(setLoadingInfo(false));
			thunkAPI.dispatch(setError(true));
		}
	},
);

export const getUsers = createAsyncThunk<void, any>(
	'/users',
	async (data, thunkAPI) => {
		try {
			thunkAPI.dispatch(setLoadingAdmin(true));
			const res = await api.get('users/', {
				params: data,
			});
			thunkAPI.dispatch(setAllUsers(res.data));
			thunkAPI.dispatch(setLoadingAdmin(false));
		} catch (err) {
			// return thunkAPI.rejectWithValue({ error: err.message });
			thunkAPI.dispatch(setLoadingAdmin(false));
			thunkAPI.dispatch(setError(true));
		}
	},
);

export const searchUsers = createAsyncThunk<void, any>(
	'/users',
	async (data, thunkAPI) => {
		const { search, ...nextData } = data;
		try {
			thunkAPI.dispatch(setLoadingAdmin(true));
			const res = await api.get(`users/search/?text=${data.search}`, {
				params: nextData,
			});
			thunkAPI.dispatch(
				setAllUsers({
					count: 0,
					next: '',
					num_pages: 0,
					current_page: 0,
					previous: '',
					results: res.data,
				}),
			);
			thunkAPI.dispatch(setLoadingAdmin(false));
		} catch (err) {
			// return thunkAPI.rejectWithValue({ error: err.message });
			thunkAPI.dispatch(setLoadingAdmin(false));
			thunkAPI.dispatch(setError(true));
		}
	},
);

export const getTopInvestors = createAsyncThunk<void, void>(
	'/statistics',
	async (_, thunkAPI) => {
		try {
			thunkAPI.dispatch(setLoadingStatistics(true));
			const res = await api.get('users/top_cb/');
			thunkAPI.dispatch(setTopInvestors(res.data));
			thunkAPI.dispatch(setLoadingStatistics(false));
		} catch (err) {
			// return thunkAPI.rejectWithValue({ error: err.message });
			thunkAPI.dispatch(setLoadingStatistics(false));
			thunkAPI.dispatch(setError(true));
		}
	},
);

export const changeUserAll = createAsyncThunk<void, any>(
	'/users',
	async (data, thunkAPI) => {
		try {
			thunkAPI.dispatch(setLoadingInfo(true));
			const res = await api.patch('users/all/', data);
			thunkAPI.dispatch(setSuccessAdmin(true));
			thunkAPI.dispatch(setLoadingInfo(false));
		} catch (err) {
			// return thunkAPI.rejectWithValue({ error: err.message });
			thunkAPI.dispatch(setLoadingInfo(false));
			thunkAPI.dispatch(setError(true));
		}
	},
);

export const readRules = createAsyncThunk<void, void>(
	'/users',
	async (_, thunkAPI) => {
		try {
			const res = await api.post('users/read_rules/');
		} catch (err) {
			thunkAPI.dispatch(setError(true));
		}
	},
);
export const statEvent = createAsyncThunk<void, string>(
	'/stats',
	async (name, thunkAPI) => {
		try {
			const res = await api.post('stats/', {
				'session_id': getItemSession('id'),
				'button_name': name,
			});
		} catch (err) {
			thunkAPI.dispatch(setError(true));
		}
	},
);
